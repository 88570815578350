
/* #hideeltonsmscreen{
  display: none;
} */


@media screen and (max-width:1000px) { 
  #hideeltonsmscreen{
    display: none;
  }
}


/* for the first homepage slider */
#herocarousel{
  height: 750px;
}


@media screen and (max-width:770px) { 
  #herocarousel{
    height: 300px;
    margin-top: 45px;
  }
}
@media (min-width:770px) and (max-width:1000px) { 
  #herocarousel{
    height: 500px; 
  }
}


#herocarousel1{
  height: 450px;
}


@media screen and (max-width:770px) { 
  #herocarousel1{
    height: 320px;
    /* width: fit-content; */
  }
}

@media (min-width:770px) and (max-width:1000px) { 
  #herocarousel1{
    height: 150px; 
  }
}

.row2h1 {
  color: white;
  font-size: 120px;
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.row-four{
  background-image: url("../../public/INTERIOR PICTURES/DSC_4994.jpg");
  background-size: cover;
  margin-left: 10px;
}


.row5p1 {
  font-size: 80px;
  font-weight: bold;
  font-family:Verdana, Geneva, Tahoma, sans-serif
/* Alert Styles */
}

.row5p2 {
  font-size: 40px;
  /* font-weight: bold; */
  /* font-family:Verdana, Geneva, Tahoma, sans-serif */
/* Alert Styles */
}
.row5btn{
  font-size: 30px;
}


.row5img{
  width: 700px;
  height: 600px;
  margin:20px;
  float:right;
}

.row6h1{
  font-size: 60px;
}

.row7{
   margin-top: 10px;
  background-image: url("../../public/INTERIOR PICTURES/DSC_5011.jpg");
  background-size: cover;
  height: 500px;
  opacity: 5;

}

.row7h1{
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 50px;
  color: #000;
}
.row7p2 {
  font-size: 60px;
}


/* .row7img {
  width: 1200px;
  height: 500px;
} */
@media screen and (max-width:770px) { 
  .row2h1 {
    font-size: 35px;
    margin-bottom: 10px;
   
  }

  .row2h3{
    font-size: 16px;

  }

  .row-four{
    margin-left: 0px;
  }


  .row5p1{
    font-size: 33px;
    /* margin-right: 15rem; */
  }
  .row5p2{
    font-size: 20px;
    /* margin-right: 15rem; */
  }

 .row5btn{
    font-size: 16px;
  }
  .row5img{
    width: 300px;
    height: 300px;
    float: start;
  }

 

  .row6h1{
    font-size: 40px;
  }

  .row7h1{
    font-size: 30px;
    font-family:s;

  }

  .row7p2 {
    font-size: 30px;
  }
}

/* Booking Page Styles */
.bookingpageh1{
  font-size: 60px;
  font-weight: bold;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  color:#000
}

.bookingpagep1{
  font-size: 25px;
  color:#000;
  /* font-family:Verdana, Geneva, Tahoma, sans-serif */
}

.bookpage{
  background-image: url("../../public/INTERIOR PICTURES/INT-35.JPG");
  background-size: cover;  

}

@media screen and (max-width:770px) { 
  .bookingpageh1{
    font-size: 50px;
    
  } 
}

/* Cart Page Styles */
@media screen and (max-width:600px) { 
  .cartspan{
    font-size: 10px;
  }
}

/* gallery page */

.gallerypage{
  background-image: url("../../public/INTERIOR PICTURES/DSC_5002.jpg");
  background-size: cover;  

}
.gallerypageh1{
  font-size: 120px;
  font-weight: bold;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color:#000
}

@media screen and (max-width:770px) { 
  .gallerypageh1{
    font-size: 50px;
    
  } 
}






/* Contact Page styles */
.contactpage{
  background-image: url("../../public/INTERIOR PICTURES/DSC_5034.jpg");
  background-size: cover;  

}






#alert{
  position:fixed;
  right: 0%; 
  margin: auto;  
  bottom: 2%;
  z-index:1;
  padding:15px;
  width: 20rem;
  font-size: 20px;
  border-left: 10px solid black;
}

#commonalert{
  position:fixed;
  right: 0%; 
  margin: auto;  
  bottom: 2%;
  z-index:1;
  padding:10px;
  width: 15rem;
  font-size: 20px;
  /* background-color: azure; */
  border-left: 10px solid black;
}





/* for swipper Compnanet */
#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}


/* Testimonails Component Styles */

.card-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.cards-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 600px;
}

.card {
  width: 18rem;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateX(-100%);
}

.card.active {
  opacity: 1;
  transform: translateX(0%);
}


/* Image Grid */
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}



/* Testimonail Component */

.textp1{
  font-size: 20px;
}

@media screen and (max-width: 800px) {
 
.textp1{
  font-size: 15px;
}
}

